@import "~@/assets/scss/variables.scss";













































































































































































































































































































































































































































































































































































































































































































































































































































































































































@media screen {
  #content-column {
    height: 100%;
  }
}
.v-app-bar::v-deep > .v-toolbar__content {
  padding: 0;
}
.toolbar-button {
  width: 4rem !important;
  &:focus {
    background-color: var(--primary) !important;
  }
}
.theme--dark.v-list-item--active {
  &:hover::before,
  &::before {
    opacity: 0;
  }
}
.v-text-field::v-deep {
  &,
  & * {
    height: 100%;
    max-height: unset !important;
  }
  .v-input__slot {
    padding-left: 12px;
  }
  .v-input__prepend-inner {
    margin: 0;
  }
}
.v-list-item__title {
  font-size: 13px;
}
.v-navigation-drawer::v-deep .v-list-item__title {
  font-weight: inherit;
}
.v-data-table::v-deep mark {
  background: rgba(98, 151, 73, 0.32);
  font-weight: bold;
}
.task-description::v-deep p:last-child {
  margin-bottom: 0;
}
.impersonator-input::v-deep .v-input__slot {
  margin-bottom: 0 !important;
}
.alert-error {
  color: red;
}
.create-account {
  padding: 24px 0;
  width: 330px;
}
.header-top {
  background: #4f771b;
  height: 30px;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .header-top {
    margin: 0;
  }
}
.header-bottom {
  padding: 0 1rem;
}
::v-deep .row-pointer tbody tr :hover {
  cursor: pointer;
}
