@import "~@/assets/scss/variables.scss";


















































































































.menu-nav {
  margin-top: 1rem;
  padding-left: 5px;
  div {
    padding: 0.2rem 0;
  }
}
