@import "~@/assets/scss/variables.scss";
.cursor {
	cursor: pointer;
}
.text-regular {
	text-transform: none;
	font-weight: normal;
	letter-spacing: 0;
	text-decoration: none;
}
.h-100 {
	height: 100% !important;
}

.v-btn:not(:hover) {
	box-shadow: none;
}
.v-btn:not(.primary):hover::before {
	opacity: 0 !important;
}
.v-toolbar__title {
	font-size: 20px;
	font-weight: 500;
}
.v-text-field--filled,
.v-text-field--full-width {
	.v-label--active {
		letter-spacing: 0.5333px;
	}
}
.v-autocomplete__content {
	font-size: 13px;
}
.v-expansion-panel-header {
	line-height: 24px;
}
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
	transform: none;
}
.v-breadcrumbs__item {
	font-weight: 500;
}
.theme--light {
	&.v-breadcrumbs .v-breadcrumbs__divider {
		color: black;
	}
	&.v-btn {
		color: rgba(0, 0, 0, 0.54);
	}
}
.v-breadcrumbs li.v-breadcrumbs__divider {
	font-size: 16px;
}
.overline {
	color: rgba(0, 0, 0, 0.54);
}
a {
	text-decoration: none;
	font-weight: 500;
}
.v-tab {
	text-transform: none !important  /* !important not necessary in dev but necessary in qa/prod...ugh */;
}
.sortable-ghost {
	outline-style: dashed !important;
	outline-color: rgba(0, 0, 0, 0.12) !important;
	cursor: unset !important;
	* {
		visibility: hidden;
	}
}
// this breaks the color prop on v-list-item-group -Jake K
// .theme--light.v-list-item--link::before {
// 	background: black;
// }
.theme--light.v-list-item:hover::before {
	opacity: 0.12;
}
.v-application .text-small-caption {
	font-size: 10px !important;
	line-height: 12px !important;
	letter-spacing: 0.4px !important;
}
.v-btn:not(.v-btn--outlined).info,
.v-btn:not(.v-btn--outlined).dark {
	color: white;
} // TODO remove someday
.theme--light.v-chip--active {
	&::before {
		opacity: 1 !important;
	}
	.v-chip__content {
		color: white;
		position: relative;
	}
}

.v-application .v-btn.light {
	border: 1px solid rgba(0, 0, 0, 0.24) !important;
}

.v-chip.v-size--large {
	height: 40px !important;
}
/* html {
	background: #4f771b;
} */