@import "~@/assets/scss/variables.scss";


























.v-list-item--active {
  background: var(--v-primary-base);
}
