@import "~@/assets/scss/variables.scss";
































































































































































































































































































.v-text-field::v-deep {
  &,
  & * {
    height: 100%;
    max-height: unset !important;
  }
  .v-input__slot {
    padding-left: 12px;
  }
  .v-input__prepend-inner {
    margin: 0;
  }
}

.v-data-table::v-deep mark {
  background: rgba(98, 151, 73, 0.32);
  font-weight: bold;
}

.v-menu__content::v-deep {
  background-color: white !important;
}

.search-input::v-deep .v-input__slot:focus-within {
  background-color: var(--v-dark-lighten3) !important;
}

.search-input::v-deep .v-input__append-inner {
  margin-top: 0 !important;
  padding-right: 1rem !important;
}
