@import "~@/assets/scss/variables.scss";






















































































































































.error-message {
  color: red;
  font-weight: bold;
}
.content-wrapper {
  position: relative;

  .loading-wrapper {
    position: absolute;
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #fff;
  }
}
