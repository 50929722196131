@import "~@/assets/scss/variables.scss";






























































h3 {
  margin: 1rem;
}
