@import url(https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
.menu-nav[data-v-4979c040]{margin-top:1rem;padding-left:5px}.menu-nav div[data-v-4979c040]{padding:.2rem 0}
.v-list-item--active[data-v-5692c40a]{background:var(--v-primary-base)}
.v-textarea[data-v-0d097399] .v-input__append-inner{position:absolute;top:0;right:12px}.table-input-disabled[data-v-0d097399] fieldset{border-color:#e0e0e0 !important}
.v-text-field[data-v-66c7a9e6],.v-text-field[data-v-66c7a9e6] *{height:100%;max-height:unset !important}.v-text-field[data-v-66c7a9e6] .v-input__slot{padding-left:12px}.v-text-field[data-v-66c7a9e6] .v-input__prepend-inner{margin:0}.v-data-table[data-v-66c7a9e6] mark{background:rgba(98,151,73,.32);font-weight:bold}.v-menu__content[data-v-66c7a9e6]{background-color:#fff !important}.search-input[data-v-66c7a9e6] .v-input__slot:focus-within{background-color:var(--v-dark-lighten3) !important}.search-input[data-v-66c7a9e6] .v-input__append-inner{margin-top:0 !important;padding-right:1rem !important}
h3[data-v-23773aa9]{margin:1rem}
.error-message[data-v-1ed1119b]{color:red;font-weight:bold}.content-wrapper[data-v-1ed1119b]{position:relative}.content-wrapper .loading-wrapper[data-v-1ed1119b]{position:absolute;width:100%;z-index:999;display:flex;flex-direction:row;justify-content:center;background:#fff}
.v-application--wrap>.v-navigation-drawer .v-icon,.v-application--wrap>.v-navigation-drawer input::placeholder,.v-application--wrap>.v-toolbar .v-icon,.v-application--wrap>.v-toolbar input::placeholder{color:rgba(255,255,255,.8)}.flip-list-move{transition:transform .5s}.wide-panel>.v-expansion-panel-content__wrap{padding:0 0 16px}.bottom-toolbar{display:flex;flex-direction:column-reverse}.bottom-toolbar .ql-toolbar.ql-snow+.ql-container.ql-snow{border-top:1px solid #ccc}.newsletter-email{background-color:#fff}.primary-nav:not(.dropdown-nav):not(.show-nav){display:none}
/* ==========================================================================
   Header
   ========================================================================== */
@font-face {
	font-family: 'DIN Next LT Pro-Bold Condensed';
	src: url('/fonts/DINNextLTPro-BoldCondensed.ttf') format('truetype'),
	url('/fonts/DINNextLTPro-BoldCondensed.woff2') format('woff2'),
	url('/fonts/DINNextLTPro-BoldCondensed.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}
a {
  text-decoration: none;
}
* {
  box-sizing: border-box;
}
.invisible {
  visibility: hidden;
}
@media (min-width: 768px){
.col-sm-4 {
    width: 33.33333333%;
}
.col-sm-9 {
    width: 75%;
}
}
@media (min-width: 768px) {
.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    float: left;
}
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 768px) {
.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    float: left;
}
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
#page-header {
  padding-top: 0;
  padding-bottom: 0;
  position: sticky;
  z-index: 50;
  left: 0;
  right: 0;
  top: 0;
  font-size: 18px;
}
#page-header #logo {
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  justify-content: center;
}
#page-header img {
  padding: 6px 0;
  max-height: 50px;
}
#page-header.agingenuity img {
  padding: 0px 0;
}
#page-header .top-flex {
  display: flex;
  align-items:center;
  height: 75px;
  width: 100%;
  margin: 0;
}
@media(max-width: 991px){
#page-header img {
    max-width: 87px;
    max-height: 60px;
}
#page-header .top-flex {
    height: 65px;
}
#internal {
    height: 95px;
}
}
.header-bottom {
  background: white;
  z-index: 0;
}
.divider {
  height: 60px;
  width: 2px;
  background: #4f771b;
  font-weight: 100;
  z-index: 3;
}
.primary-nav, .top-nav {
  text-transform: uppercase;
}
.header-top {
  background: #4f771b;
  height: 30px;
  display: flex;
  align-items: center;
}
@media(max-width:767px){
.header-top {
    margin: 0;
}
}
.top-nav a {
  font-family: sans-serif;
}
#page-header #header-nav a {
  /*color: #72809e;*/
  margin: 20px 10px;
  text-decoration: none !important;
  padding: 0;
  /*font-size: .9em;*/
  font-family: Roboto, sans-serif;
  font-size: 15px;
  color: #939285;
  font-weight: 500;
}
.primary-nav {
  padding-left: 14px;
  padding-right: 14px;
  font-family: sans-serif;
}
.primary-nav .dropdown-caret {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 18px;
  margin-top: -35px;
  fill: white;
  height: 17px;
}
#header-nav .primary-nav.dropdown-nav {
  width: 100%;
  padding: 0;
  position: relative;
}
#header-nav .primary-nav.dropdown-nav > ul {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  /*background: #D3D9E7;*/
  transition: 0.3s ease;
}
#page-header ul {
  margin-bottom: 0;
}
#page-header .btn-primary {
  margin-left: 15px;
}
#page-header #search .form-control {
  background: 0;
  border: 0;
  border-bottom: 3px solid #FFF;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  transition: .2s width;
  box-shadow: none;
  color: #FFF;
  display: inline-block;
  width: 100px;
}
@media (min-width: 1200px) {
#page-header #search .form-control {
    width: 0;
}
#page-header #search:hover .form-control {
    width: 200px;
}
}
@media (max-width: 991px){
#page-header #header-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
}
.header-top #search {
  /*display: none;*/
  padding-left: 7px;
}
@media(max-width: 767px){
.header-top .dropdown-nav {
    display: none;
}
}
#header-nav nav > ul > li ul {
  transition: 0.3s ease;
  width: unset;
  margin: unset;
  margin-bottom: -30px;
  position: absolute;
  flex-direction: column;
  padding:25px;
  justify-content: flex-start;
  top: 0;
  margin-top: 60px;
  z-index: -1;
  visibility: hidden;
  background: white;
  opacity: 0;
}
#header-nav nav > ul > li:not(.reversed) ul { left: 0;
}
#header-nav nav > ul > li > ul > li ul {
  display:none;
}
/*.primary-nav.dropdown-nav li.reversed > ul a {*/
/*text-align: left;*/
/*}*/
/*#header-nav nav > ul > li > ul > li {*/
/*background: #D3D9E7;*/
/*}*/
.dropdown-nav > ul > li > ul {
  transition: 0.3s ease;
  width: unset;
  margin: unset;
  margin-bottom: -30px;
  position: absolute;
  flex-direction: column;
  padding: 25px;
  justify-content: flex-start;
  top: 0;
  margin-top: 37px;
  z-index: -1;
  visibility: hidden;
  background: white;
  opacity: 0;
}
.dropdown-nav > ul > li:not(.reversed) > ul { left: 0;
}
nav.dropdown-nav > ul > li > ul > li a {
  font-weight: 400;
  color: #888 !important;
  letter-spacing: 1px;
  font-size: 1em;
}
#header-nav nav > ul > li > ul > li a {
  margin: 5px 1rem !important;
  font-weight: 400;
  color: #888 !important;
  letter-spacing: 1px;
  font-size: 1em;
}
#header-nav nav > ul > li.open > ul,nav.dropdown-nav > ul > li.open > ul {
  z-index:2;
  opacity: 1;
  visibility: visible;
}
.dropdown-nav ul .open > ul {
  display: flex;
}
.dropdown-nav ul ul a {
  /*color: #4f771b;*/
  /*font-size: 12px !important;*/
}
.dropdown-nav ul ul a:hover {
  text-decoration: underline !important;
  /*text-decoration: none;*/
}
#page-header .top-nav > ul > li > ul > li a {
  font-size: 12px;
}
.dropdowns-menu {
  left: 50%;
  right: auto;
  text-align: center;
  transform: translate(-50%, 0);
}
.open>.dropdowns-menu {
  display: flex;
  flex-direction: column;
  padding: 4px 10px;
}
.open>.dropdowns-menu .dropdowns-item {
  color: #4f771b;
  font-size: 16px;
  padding: 4px 0;
  text-decoration: none;
}
/*@media(min-width: 991px){*/
  /*#header-nav {*/
    /*display: flex !important;*/
  /*}*/
/*}*/
@media(max-width: 990px){
#header-nav {
    display: none !important;
}
.header-bottom .top-flex {
    justify-content: space-between;
}
.header-bottom .top-flex .col-sm-4 {
    padding:0;
}
}
.logo-sm {
  display: none;
}
@media(max-width: 991px){
.logo-sm {
    display: block;
}
.logo-lg {
    display: none;
}
}
.nav-green-border {
  border-bottom: 4px solid #4f771b;
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 100%;
}
.nav-cur > a {
  color: #4f771b !important;
}
#page-header #search .btn {
  background: 0;
  color: #FFF;
  padding: 0;
  outline: none;
}
@media(max-width: 991px){
#page-header #search .btn {
    padding-right: 15px;
}
}
#page-header #search .btn:hover {
  color: #c8d6ff;
}
#page-header .fa {
  font-size: 24px;
}
.dropdown-nav > ul > li { justify-content: flex-end;
}
.primary-nav:not(.dropdown-nav):not(.show-nav) {
  display: none;
}
.top-nav:not(.dropdown-nav):not(.show-nav) {
  display: none;
}
#header-nav {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 768px) {
.header-container {
    width: 750px;
}
}
@media (min-width: 992px) {
.header-container {
    width: 970px;
}
}
@media (min-width: 1220px) {
.header-container {
    width: 1230px;
}
}
@media (min-width: 1400px) {
.header-bottom .header-container {
    width: 1400px;
}
}
.customer-login-btn {
  background: #629749;
  color: white !important;
  font-size: 14px;
  border-radius: 4px;
  min-width: 160px;
  text-align: center;
  padding: 8px 0;
  text-decoration: none !important;
  outline: none !important;
  margin-right: 10px;
  float: right;
}
@media (min-width: 992px){
.customer-login-btn {
    margin-left: 80px;
    margin-right: 0;
}
}
.customer-login-btn:hover {
  background: #558440;
}
@media(min-width: 991px){
#hamburger {
    display: none;
}
}
@media(max-width: 460px) {
.mobile-cust-name {
    display: none;
}
.customer-login-btn {
    min-width: 80px;
}
}
.cust-head-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8px;
}
.cust-photo {
  width: 30px;
  padding: 0 !important;
  max-height: 40px !important;
}
.dropdowns-menu .cust-photo {
  width: 35px;
}
.primary-nav:not(.sidebar-nav) .nav-bg {
  display: none;
}
.parent-page {
  display: flex;
  align-items: center;
}
.nav-more {
  display: none;
}
/*.hide-more {*/
  /*display: none;*/
/*}*/
@media(min-width: 992px) and (max-width: 1400px){
.hide-more {
    display: none;
}
.nav-more {
    display: flex;
}
}
.logo-container {
  width: unset;
  padding-right: 0;
}
.top-flex {
  justify-content: space-between;
}
@media(max-width: 1380px) {
.header-bottom .container.clearfix {
    width: unset;
}
}
@media(min-width: 991px) {
.header-bottom .container.clearfix {
    padding: 0;
}
}
@media(max-width: 1299px){
#header-nav {
    width: unset;
}
}
.header-bottom .container {
  max-width: 100%;
}
#header-nav nav > ul > li > ul > li.open > ul {
  display: block;
  visibility: visible;
  opacity: 1;
  left: 100%;
  top: -20px;
  margin-top: 0;
}
.rowfix:before, .rowfix:after { content: none;
}
.cust-dropdown {
  padding-right: 20px;
  display: none;
}
@media(max-width: 767px){
.cust-dropdown {
    padding-right: 10px;
}
}





/* ==========================================================================
   Footer
   ========================================================================== */
@media (min-width: 768px) {
.footer-container {
    width: 750px;
}
}
@media (min-width: 992px) {
.footer-container {
    width: 970px;
}
}
@media (min-width: 1200px) {
.footer-container {
    width: 1170px;
}
}
@media (min-width: 768px){
.footer-col {
    width: 25%;
}
}
@media (max-width: 767px){
.footer-col {
    width: 50%;
}
}
@media (min-width: 768px){
.footer-col {
    float: left;
}
}
.footer-row:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.footer-row {
  display: inline-block;
}
html[xmlns] .footer-row {
  display: block;
}
* html .footer-row {
  height: 1%;
}
@media(max-width: 622px){
.footer-site-info {
    flex-direction: column;
}
.footer-site-info .left {
    padding-bottom: 8px;
}
.footer-line {
    margin: 20px 0;
}
}
@media(max-width: 500px){
#FOOTER .footer-row > div:first-child br {
    display: none;
}
#FOOTER .footer-row > div:nth-child(3),#FOOTER .footer-row > div:nth-child(4) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
}
@media(max-width: 390px){
.footer-site-info .left {
    flex-direction: column;
}
#FOOTER .footer-row > div:nth-child(1),#FOOTER .footer-row > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
}
.footer-site-info .left {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.footer-site-info .left > * {
  margin-right: 10px;
}
.footer-site-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 12px;
}
.footer-message p {
  margin: 0 20px 0 0;
  color: white;
  font-style: normal;
  font-weight: bold;
  line-height: 56px;
  letter-spacing: 0.15px;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
}
.footer-message h1,
.footer-message h2,
.footer-message h3,
.footer-message h4,
.footer-message h5,
.footer-message h6 {
  font-family: 'DIN Next LT Pro-Bold Condensed', sans-serif;
}
#FOOTER h1,
#FOOTER h2,
#FOOTER h3,
#FOOTER h4,
#FOOTER h5,
#FOOTER h6 {
  font-family: 'DIN Next LT Pro-Bold Condensed', sans-serif;
}
#FOOTER a {
  text-decoration: none;
  font-size: 14px;
  color: white;
  display: block;
  line-height: 20px;
}
#FOOTER p {
  font-size: 14px;
  margin: 0;
}
.footer-social .social-link {
  margin-right: 5px;
  background: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #1F1F1F !important;
  text-decoration: none !important;
}
.newsletter-email {
  font-size: 12px;
  color: black;
  padding: 7px 10px !important;
  border-radius: 4px !important;
  border-style: none !important;
  width: 100% !important;
}
#mc_embed_signup .size1of2 {
  display: flex !important;
  margin-top: 5px;
}
#mc_embed_signup .size1of2 {
  width: 100% !important;
}
#mc_embed_signup .mc-field-group input:not(.newsletter-email):not(.birthday) {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0px 20px !important;
  margin-bottom: 4px !important;
}
.mc-field-group {
  padding-bottom: 0 !important;
  min-height: 35px !important;
  max-width: 212px !important;
}
#mc_embed_signup form {
  padding: 0 0 10px 0 !important;
}
@media(max-width: 1199px){
.footer-message p {
    font-size: 25px;
}
}
@media (max-width: 767px){
.footer-message .footer-container {
    display: inline-block !important;
}
}
@media (max-width: 767px){
.footer-message p {
    text-align: center;
    line-height: 1.1;
    padding-bottom: 10px;
    margin: 0;
}
}
.img-circle {
  border-radius: 50%;
}
#hamburger {
  font-family: 'Oswald', sans-serif;
  color: #1f1f1f;
}
#hamburger span {
  font-size: 33px !important;
  display: flex;
  align-items: center;
}
.dropdowns, .dropup {
  position: relative;
}
.text-center {
  text-align: center;
}
.dropdowns-toggle {
  background: white !important;
  color: #4f771b !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.dropdowns-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdowns-menu>li>a {
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 43px;
}
.animated {
  animation-duration: 0.5s;
}
.no-fixed-bg * {
  background-attachment: scroll !important;
}
.fadeInUp {
  animation-name: fadeInUp;
}
.pull-right {
  float: right!important;
}
.dropdowns-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdowns-backdrop {
  z-index: 2;
}
.dropdowns-backdrop{position:fixed;top:0;right:0;bottom:0;left:0;z-index:990}
.primary-nav.dropdown-nav > ul > li > ul > li .nav-green-border {
  bottom: 2px;
}
.primary-nav.dropdown-nav > ul > li > ul li {
  display: flex;
  justify-content: space-between;
}
.portal-img {
  background: #33691E;
  color: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 16px;
}
#portal-menu .portal-img {
  width: 40px;
  height: 40px;
}
#portal-menu {
  overflow: hidden;
  outline: none;
  border: none;
  padding: 10px 0;
  box-shadow: 0px 8px 10px rgb(0 0 0 / 14%), 0px 3px 14px rgb(0 0 0 / 12%), 0px 4px 5px rgb(0 0 0 / 20%);
  border-radius: 4px;
}
#portal-menu a {
  display: flex;
  align-items: center;
  padding:5px 15px;
  width: 190px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  font-weight: 400;
}
#portal-menu a:hover {
  background: rgba(0,0,0,.12);
}


@media screen{#content-column[data-v-6a241973]{height:100%}}.v-app-bar[data-v-6a241973]>.v-toolbar__content{padding:0}.toolbar-button[data-v-6a241973]{width:4rem !important}.toolbar-button[data-v-6a241973]:focus{background-color:var(--primary) !important}.theme--dark.v-list-item--active[data-v-6a241973]:hover::before,.theme--dark.v-list-item--active[data-v-6a241973]::before{opacity:0}.v-text-field[data-v-6a241973],.v-text-field[data-v-6a241973] *{height:100%;max-height:unset !important}.v-text-field[data-v-6a241973] .v-input__slot{padding-left:12px}.v-text-field[data-v-6a241973] .v-input__prepend-inner{margin:0}.v-list-item__title[data-v-6a241973]{font-size:13px}.v-navigation-drawer[data-v-6a241973] .v-list-item__title{font-weight:inherit}.v-data-table[data-v-6a241973] mark{background:rgba(98,151,73,.32);font-weight:bold}.task-description[data-v-6a241973] p:last-child{margin-bottom:0}.impersonator-input[data-v-6a241973] .v-input__slot{margin-bottom:0 !important}.alert-error[data-v-6a241973]{color:red}.create-account[data-v-6a241973]{padding:24px 0;width:330px}.header-top[data-v-6a241973]{background:#4f771b;height:30px;display:flex;align-items:center}@media(max-width: 767px){.header-top[data-v-6a241973]{margin:0}}.header-bottom[data-v-6a241973]{padding:0 1rem}[data-v-6a241973] .row-pointer tbody tr :hover{cursor:pointer}
.cursor{cursor:pointer}.text-regular{text-transform:none;font-weight:normal;letter-spacing:0;text-decoration:none}.h-100{height:100% !important}.v-btn:not(:hover){box-shadow:none}.v-btn:not(.primary):hover::before{opacity:0 !important}.v-toolbar__title{font-size:20px;font-weight:500}.v-text-field--filled .v-label--active,.v-text-field--full-width .v-label--active{letter-spacing:.5333px}.v-autocomplete__content{font-size:13px}.v-expansion-panel-header{line-height:24px}.v-select.v-select--is-menu-active .v-input__icon--append .v-icon{transform:none}.v-breadcrumbs__item{font-weight:500}.theme--light.v-breadcrumbs .v-breadcrumbs__divider{color:#000}.theme--light.v-btn{color:rgba(0,0,0,.54)}.v-breadcrumbs li.v-breadcrumbs__divider{font-size:16px}.overline{color:rgba(0,0,0,.54)}a{text-decoration:none;font-weight:500}.v-tab{text-transform:none !important}.sortable-ghost{outline-style:dashed !important;outline-color:rgba(0,0,0,.12) !important;cursor:unset !important}.sortable-ghost *{visibility:hidden}.theme--light.v-list-item:hover::before{opacity:.12}.v-application .text-small-caption{font-size:10px !important;line-height:12px !important;letter-spacing:.4px !important}.v-btn:not(.v-btn--outlined).info,.v-btn:not(.v-btn--outlined).dark{color:#fff}.theme--light.v-chip--active::before{opacity:1 !important}.theme--light.v-chip--active .v-chip__content{color:#fff;position:relative}.v-application .v-btn.light{border:1px solid rgba(0,0,0,.24) !important}.v-chip.v-size--large{height:40px !important}

/*# sourceMappingURL=app.9c1bd97a.css.map*/