@import "~@/assets/scss/variables.scss";




























































































































.v-textarea::v-deep .v-input__append-inner {
  position: absolute;
  top: 0;
  right: 12px;
}
.table-input-disabled::v-deep fieldset {
  border-color: #e0e0e0 !important;
}
